export const goals = [
  {
    id: 1,
    title: "Perder peso",
  },
  {
    id: 2,
    title: "Manterner mi peso",
  },
  {
    id: 3,
    title: "Ganar masa muscular",
  },
];

export const physicalLevel = [
  {
    id: 1,
    title: "Principiante",
  },
  {
    id: 2,
    title: "Moderadamente en forma",
  },
  {
    id: 3,
    title: "En forma",
  },
  {
    id: 4,
    title: "En plena forma",
  },
];

export const impediments = [
  {
    id: 1,
    title: "Poca Orientación",
  },
  {
    id: 2,
    title: "Falta de motivación",
  },
  {
    id: 3,
    title: "No tengo tiempo",
  },
  {
    id: 4,
    title: "No tengo implementos",
  },
];

export const howDidYouKnow = [
  {
    id: 1,
    title: "Redes Sociales",
  },
  {
    id: 2,
    title: "Google",
  },
  {
    id: 3,
    title: "Un amig@",
  },
  {
    id: 4,
    title: "Otro sitio",
  },
];

export const frequency = [
  { id: 1, title: "Poco o ningún ejercicio" },
  { id: 2, title: "Ejercicio ligero" },
  { id: 3, title: "Ejercicio moderado" },
  { id: 4, title: "Ejercicio fuerte" },
  { id: 5, title: "Ejercicio muy fuerte" },
];

export const publicProfile = [
  {
    id: 1,
    title: "Si",
  },
  {
    id: 2,
    title: "No",
  },
];

export const fatPercentage = [
  {
    id: 1,
    title: "15-17%",
  },
  {
    id: 2,
    title: "18-22%",
  },
  {
    id: 3,
    title: "23-25%",
  },
  {
    id: 4,
    title: "26-30%",
  },
  {
    id: 5,
    title: "31-35%",
  },
  {
    id: 6,
    title: "35% +",
  },
];

export const CoachsType = [
  {
    id: 1,
    title: "COACH",
  },
  {
    id: 2,
    title: "NUTRICIONISTA",
  },
];

export const ClassLevels = [
  {
    id: 1,
    title: "Principiante",
  },
  {
    id: 2,
    title: "Intermedio",
  },
  {
    id: 3,
    title: "Avanzado",
  },
];

export const UserLevels = [
  {
    id: 1,
    title: "Principiante",
  },
  {
    id: 2,
    title: "Intermedio",
  },
  {
    id: 3,
    title: "Avanzado",
  },
];
