"use client";
import Image from "next/image";
import { useState } from "react";

export default function PlanPersonalizado() {
  const [currentStep, setCurrentStep] = useState(1);
  const dataSteps = [
    {
      id: 1,
      title: "CONOCER TU ESTILO DE VIDA Y OBJETIVOS",
      description:
        "Conocer estos puntos permite crear un plan según tus necesidades.",
      items: ["OBJETIVOS", "FRECUENCIA", "TIPO DE ALIMENTACIÓN"],
      img: "https://maluapp.b-cdn.net/resources/FOTO%20PLAN-02.webp",
    },
    {
      id: 2,
      title: "SABER DE TU CONDICIÓN FÍSICA",
      description:
        "Conocer estos puntos permite crear un plan según tus necesidades.",
      items: ["HIDRATACIÓN", "NIVEL ACTUAL", "GRASA CORPORAL"],
      img: "https://maluapp.b-cdn.net/resources/FOTO%20PLAN-02.webp",
    },
    {
      id: 3,
      title: "CREAR EL PLAN DE EJERCICIOS PARA TI",
      description:
        "Conocer estos puntos permite crear un plan según tus necesidades.",
      items: ["RECETARIO", "ENTRENAMIENTOS", "PROGRAMAS", "CALENDARIO"],
      img: "https://maluapp.b-cdn.net/resources/FOTO%20PLAN-02.webp",
    },
    {
      id: 4,
      title: "ACOMPAÑARTE Y DAR MOTIVACIÓN",
      description:
        "Conocer estos puntos permite crear un plan según tus necesidades.",
      items: [
        "ESTADISTICAS DE AVANCE",
        "CLASES EN VIVO",
        "MONITOREO DE PROGRESO",
        "CHATEA CON MALÚ",
      ],
      img: "https://maluapp.b-cdn.net/resources/FOTO%20PLAN-02.webp",
    },
  ];

  return (
    <div className="container mx-auto flex flex-col gap-10 items-center py-16 px-5">
      <div className=" container mx-auto text-center">
        <span className="font-light text-3xl">
          MÁS DE 10.000 PERSONAS HAN LOGRADO SUS OBJETIVOS
        </span>
        <h3 className="font-bold text-4xl mt-5">
          CREEMOS JUNTAS TU PLAN PERSONALIZADO
        </h3>
      </div>
      <div className="w-full">
        <div className="block whitespace-nowrap overflow-auto lg:flex justify-center">
          <button
            className={`inline-flex m-2 border border-deep-purple px-14 py-2  text-xl rounded-full hover:bg-deep-purple hover:text-cream ${
              currentStep === 1
                ? "bg-deep-purple text-cream"
                : "text-deep-purple"
            }`}
            onClick={() => setCurrentStep(1)}
          >
            PASO 1
          </button>
          <button
            className={`inline-flex m-2 border border-deep-purple px-14 py-2  text-xl rounded-full hover:bg-deep-purple hover:text-cream ${
              currentStep === 2
                ? "bg-deep-purple text-cream"
                : "text-deep-purple"
            }`}
            onClick={() => setCurrentStep(2)}
          >
            PASO 2
          </button>
          <button
            className={`inline-flex m-2 border border-deep-purple px-14 py-2  text-xl rounded-full hover:bg-deep-purple hover:text-cream ${
              currentStep === 3
                ? "bg-deep-purple text-cream"
                : "text-deep-purple"
            }`}
            onClick={() => setCurrentStep(3)}
          >
            PASO 3
          </button>
          <button
            className={`inline-flex m-2 border border-deep-purple px-14 py-2  text-xl rounded-full hover:bg-deep-purple hover:text-cream ${
              currentStep === 4
                ? "bg-deep-purple text-cream"
                : "text-deep-purple"
            }`}
            onClick={() => setCurrentStep(4)}
          >
            PASO 4
          </button>
        </div>
      </div>
      <div className="w-full grid  grid-cols-1  lg:grid-cols-2 gap-5 items-center">
        <div className="max-w-2xl flex flex-col gap-5 justify-start">
          <h1 className="font-bold text-4xl">
            {dataSteps[currentStep - 1].title}
          </h1>
          <p className="text-lg font-medium mt-5">
            Conocer estos puntos permite crear un plan según tus necesidades.
          </p>

          <ul className=" list-disc pl-8">
            {dataSteps[currentStep - 1].items.map((item, index) => (
              <li key={index} className="text-xl">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full h-[400px] max-w-[600px]">
          <Image
            src={dataSteps[currentStep - 1].img}
            width={400}
            height={400}
            alt="Imagen Login"
            quality={100}
            priority={true}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
}
