"use client";
import { useState, useEffect } from "react";
import axiosInstance from "@/utils/axiosInstance";
import Slider from "react-slick";
import CoachsCard from "@/components/ui/coachs/CoachsCard";
import { CoachsType } from "@/utils/valuesFields";

interface Coach {
  id: number;
  name: string;
  img_url: string;
  type: number;
  username: string;
}

export default function CoachsCarousel() {
  const [coachs, setCoachs] = useState<Coach[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(`${process.env.NEXT_PUBLIC_API_URL_BASE}/coachs`)
      .then((response) => {
        //console.log(response.data);
        setCoachs(response.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mx-auto py-16 px-5">
      <div className="text-center">
        <span className="font-light text-3xl">COACHS Y NUTRICIONISTAS</span>
        <h2 className="font-bold text-4xl mt-5">
          UN EQUIPO DE ESPECIALISTAS CREADO PARA TI
        </h2>
      </div>
      <div className="mt-10">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <Slider
            dots={true}
            arrows={false}
            infinite={true}
            speed={500}
            slidesToShow={5}
            slidesToScroll={5}
            responsive={[
              {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
          >
            {coachs.map((coach) => (
              <CoachsCard
                key={coach.id}
                username={coach.username}
                img_url={
                  coach.img_url
                    ? coach.img_url
                    : "https://maluapp.b-cdn.net/resources/Women.png"
                }
                type={CoachsType.find((item) => item.id === coach.type)?.title}
                name={coach.name}
              />
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}
