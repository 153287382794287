"use client";
import { StarIcon } from "@/components/ui/Icons";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef } from "react";

export default function HomeComponent() {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marqueeElem = marqueeRef.current as HTMLDivElement | null;
    let scrollAmount = 0;

    function scrollMarquee() {
      if (marqueeElem) {
        marqueeElem.scrollLeft += 2;
        scrollAmount += 2;
        if (scrollAmount >= marqueeElem.scrollWidth / 2) {
          scrollAmount = 0;
        }
        requestAnimationFrame(scrollMarquee);
      }
    }
    scrollMarquee();
  }, [marqueeRef]);

  return (
    <>
      <div className="relative">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 items-center py-28 px-5">
          <div className="flex flex-col gap-5 items-start lg:-mt-32">
            <span className="font-light text-3xl">
              SÉ PARTE DE NUESTRO CLUB
            </span>
            <h1 className="font-bold text-5xl">MALÚ PÉREZ ONLINE</h1>
            <p className="font-light text-xl">
              Obtén tu plan personalizado de nutrición y entrenamiento, donde
              podrás entrenar junto a Malú Pérez donde y cuando quieras. Con mi
              nueva plataforma tendrás acceso ilimitado a clases en vivo y On
              demand. Entrenemos juntas ¡Ahora! Y descubre tu máximo potencial.
            </p>
            <Link
              href="/register"
              className="bg-bright-pink px-5 py-2 rounded-full text-cream"
            >
              {" "}
              ¡CLASE GRATIS!{" "}
            </Link>
          </div>
          <div className="bg-[url('https://maluapp.b-cdn.net/resources/circulo_degradado.svg')] bg-no-repeat">
            <Image
              src="https://maluapp.b-cdn.net/resources/img_home%20(1).webp"
              alt="girl"
              width={600}
              height={600}
            />
          </div>
        </div>
        <div className="bg-gradient-to-r from-orange to-bright-pink py-2 absolute w-full h-fit left-0 bottom-48">
          <div className="marquee">
            <div ref={marqueeRef} className="marquee-content inline-flex gap-3">
              {[...Array(10)].map((_, i) => (
                <div
                  className="inline-flex items-center gap-3 text-cream fill-cream"
                  key={i}
                >
                  <span className="font-light text-3xl">
                    MALÚ TRAINING CLUB
                  </span>{" "}
                  <StarIcon className="w-8 h-8 fill-red" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
